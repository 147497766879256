import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { ArrowDownIcon } from '@heroicons/react/outline';
import { GatsbyImage } from 'gatsby-plugin-image';
import { AnchorLink } from 'gatsby-plugin-anchor-links';
import ContactUs from '../components/ContactUs';
import SEO from '../components/SEO';

export default function AboutUsPage() {
  const data = useStaticQuery(graphql`
  query {
    grandmaster: file(relativePath: { eq: "grandmaster-brown.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
        
      }
    }
    handshake: file(relativePath: { eq: "master-brown-handshake.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, transformOptions: {cropFocus: NORTH }, )
      }
    }
  }
`);
  return (
    <>
      <SEO title="About Us" description="Learn more about our System, our history, and our future as a Self Defense Dojo." image={data.handshake} />
      <section className="z-20 shadow-md lg:relative filter">
        <div className="relative z-0 w-full h-64 lg:hidden sm:h-72 md:h-96 lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2 lg:h-full">
          <GatsbyImage
            className="w-full h-full"
            imgClassName="object-top"
            image={data.handshake.childImageSharp.gatsbyImageData}
            alt="Master Brown handshake with student"
          />
          <div className="absolute inset-0 bg-gradient-to-r from-gray-200 to-gray-500 mix-blend-multiply" />
        </div>
        <div className="z-10 w-full pt-16 pb-20 mx-auto text-center max-w-7xl lg:py-40 lg:text-left">
          <div className="z-10 px-4 lg:w-1/2 sm:px-8 xl:pr-16 ">
            <h1 className="text-4xl font-extrabold tracking-tight text-gray-900 sm:text-5xl md:text-6xl lg:text-5xl xl:text-6xl">
              <span className="block xl:inline">It starts with</span>
              {' '}
              <span className="font-black text-transparent bg-clip-text bg-gradient-to-r from-secondary to-secondary-light sm:pb-5">Self Confidence</span>
            </h1>
            <p className="max-w-md mx-auto mt-3 text-lg text-gray-500 sm:text-xl md:mt-5 md:max-w-3xl">
              Yuan Yen Do is a self defense based martial arts system which centers around
              developing the character of the members through a fun and structured teaching system.
            </p>
            <div className="mt-10 sm:flex sm:justify-center lg:justify-start">

              <div className="mt-3 rounded-md shadow sm:mt-0 ">
                <AnchorLink
                  to="/about-us/#overview"
                  title="Our History"
                  className="flex items-center justify-center w-full px-8 py-3 text-base font-medium bg-white border border-transparent rounded-md text-secondary hover:bg-gray-50 md:py-4 md:text-lg md:px-10"
                >
                  <ArrowDownIcon className="w-5 h-5 pr-2" />
                  Learn more
                </AnchorLink>
              </div>
            </div>
          </div>
        </div>
        <div className="relative z-0 hidden w-full h-64 lg:block sm:h-72 md:h-96 lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2 lg:h-full">

          <GatsbyImage
            className="w-full h-full"
            imgClassName="object-top"
            image={data.handshake.childImageSharp.gatsbyImageData}
            alt="Master Brown handshake with student"
          />
          <div className="absolute inset-0 bg-gradient-to-r from-gray-200 to-gray-500 mix-blend-multiply" />

        </div>
      </section>
      <section className="overflow-hidden bg-primary" id="overview">
        <div className="relative px-4 py-16 mx-auto max-w-7xl sm:px-6 lg:px-8">
          <div className="absolute top-0 bottom-0 hidden w-screen lg:block bg-primary left-3/4" />
          <div className="mx-auto text-base max-w-prose lg:grid lg:grid-cols-2 lg:gap-8 lg:max-w-none">
            <div>
              <h2 className="text-base font-semibold tracking-wide uppercase text-secondary">About Us</h2>
              <h3 className="mt-2 text-3xl font-extrabold leading-8 tracking-tight text-gray-50 sm:text-4xl">
                The Way of Change
              </h3>
            </div>
          </div>
          <div className="mt-8 lg:grid lg:grid-cols-2 lg:gap-8">
            <div className="relative lg:row-start-1 lg:col-start-2">
              <svg
                className="absolute top-0 right-0 hidden -mt-20 -mr-20 lg:block"
                width={404}
                height={384}
                fill="none"
                viewBox="0 0 404 384"
                aria-hidden="true"
              >
                <defs>
                  <pattern
                    id="de316486-4a29-4312-bdfc-fbce2132a2c1"
                    x={0}
                    y={0}
                    width={20}
                    height={20}
                    patternUnits="userSpaceOnUse"
                  >
                    <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                  </pattern>
                </defs>
                <rect width={404} height={384} fill="url(#de316486-4a29-4312-bdfc-fbce2132a2c1)" />
              </svg>
              <div className="relative mx-auto text-base max-w-prose lg:max-w-none">
                <figure>
                  <div className="aspect-w-12 aspect-h-7 lg:aspect-none">
                    <GatsbyImage
                      className="object-cover object-center rounded-lg shadow-lg"
                      imgClassName="object-top"
                      image={data.grandmaster.childImageSharp.gatsbyImageData}
                      alt="Photograph of Grandmaster Brown"

                    />
                  </div>
                  <figcaption className="flex mt-3 text-sm text-gray-500">
                    <span className="ml-2">Photograph of Grandmaster Michael H. Brown</span>
                  </figcaption>
                </figure>
              </div>
            </div>
            <div className="mt-8 lg:mt-0">
              <div className="mx-auto text-base prose max-w-prose lg:max-w-none">
                <p className="text-lg text-gray-200">
                  Yuan Yen Do, which when translated from the Chinese means
                  “The Way of Change,” is based on the premise “Learn the Moves
                  within the Moves – the Transition.” Yuan Yen Do seamlessly
                  combines the hard style of Karate and the soft style of Kung Fu,
                  with the emphasis on the extremes of the two. The results vastly
                  improve delivery speed and power by allowing the sequentially
                  arranged moves to progress without transitional lapses, one move
                  feeding the next in a chain reaction where the whole becomes
                  greater than the sum of its parts.
                </p>
                <h3 className="mt-5 text-lg font-semibold text-gray-200" id="our-system">Our System</h3>
                <p className="mt-5 text-lg text-gray-200">
                  Yuan Yen Do is a Martial Art that is designed to be both a defensive and offensive fighting system with multiple combinations of Blocks, Strikes, Kicks, Fakes, Feints, Checks, Deflections, Evasions, Grappling, Sweeps, Reaps, Leg Hauks, Throws, Ground Fighting and Multiple Attack Scenarios. Through the study of the Internal Systems of Tai Chi, Chi Kung and the Six Animals – Tiger, Leopard, Crane, Snake, Dragon and Monkey – each member learns to remove the “and” from Block “and” Strike, eliminating the transitions between the moves. As the Curriculum and Body Mechanics are refined, a higher level of Mind State is developed, empowering each member to effectively handle ANY opponent, regardless of age, size or strength.
                </p>
                <h3 className="mt-5 text-lg font-semibold text-gray-200" id="our-history">Our History</h3>
                <p className="mt-5 text-lg text-gray-200 ">
                  Grandmaster Michael Brown created and continually developed
                  the system of Yuan Yen Do since 1995. He passed away
                  in Jan 2018 but his legacy lives on through the
                  countless lives he touched while teaching at Yuan
                  Yen Do Karate Centers.
                </p>
                <h3 className="mt-5 text-lg font-semibold text-gray-200" id="our-future">Our Future</h3>
                <p className="mt-5 text-lg text-gray-200 ">
                  After Grandmaster&rsquo;s passing, Master Craig Brown undertook the task of continuing to develop Yuan Yen Do&rsquo;s system for future generations.
                </p>
              </div>
              {/*
              <div className="mx-auto mt-5 prose text-gray-200 prose-indigo lg:max-w-none lg:row-start-1 lg:col-start-1">
                <p>
                  Sollicitudin tristique eros erat odio sed vitae, consequat turpis elementum. Lorem nibh vel, eget
                  pretium arcu vitae. Eros eu viverra donec ut volutpat donec laoreet quam urna.
                </p>
                <p>
                  Bibendum eu nulla feugiat justo, elit adipiscing. Ut tristique sit nisi lorem pulvinar. Urna, laoreet
                  fusce nibh leo. Dictum et et et sit. Faucibus sed non gravida lectus dignissim imperdiet a.
                </p>
                <p>
                  Dictum magnis risus phasellus vitae quam morbi. Quis lorem lorem arcu, metus, egestas netus cursus. In.
                </p>
                <ul role="list">
                  <li>Quis elit egestas venenatis mattis dignissim.</li>
                  <li>Cras cras lobortis vitae vivamus ultricies facilisis tempus.</li>
                  <li>Orci in sit morbi dignissim metus diam arcu pretium.</li>
                </ul>
                <p>
                  Rhoncus nisl, libero egestas diam fermentum dui. At quis tincidunt vel ultricies. Vulputate aliquet
                  velit faucibus semper. Pellentesque in venenatis vestibulum consectetur nibh id. In id ut tempus
                  egestas. Enim sit aliquam nec, a. Morbi enim fermentum lacus in. Viverra.
                </p>
                <h3 className="text-gray-50">How we helped</h3>
                <p>
                  Tincidunt integer commodo, cursus etiam aliquam neque, et. Consectetur pretium in volutpat, diam.
                  Montes, magna cursus nulla feugiat dignissim id lobortis amet. Laoreet sem est phasellus eu proin massa,
                  lectus. Diam rutrum posuere donec ultricies non morbi. Mi a platea auctor mi.
                </p>
                <p>
                  Sagittis scelerisque nulla cursus in enim consectetur quam. Dictum urna sed consectetur neque tristique
                  pellentesque. Blandit amet, sed aenean erat arcu morbi.
                </p>
  </div> */}
            </div>
          </div>
        </div>
      </section>
      <ContactUs />
    </>
  );
}
